import { useSelector } from "react-redux";
export const setMembershipDataKeyForUpgrade = (subscriptionType) => {
  switch (subscriptionType) {
    case "standard":
      return "premium";
    default:
      return "standard";
  }
};

export const setMembershipDataKey = (subscriptionType) => {
  console.log(
    "🚀 ~ setMembershipDataKey ~ subscriptionType:",
    subscriptionType
  );
  switch (subscriptionType) {
    case "free":
      return "standard";

    default:
      return subscriptionType;
  }
};
