import findEmailPasswordStyles from "./FindEmailPasswordStyle.module.scss";
import { useAppNavigate } from "../../utils/navigate/navigate";

const FindEmailCompleteButtons = ({ isFindPasswordComplete = false }) => {
  const { goToLogin, goToFindEmailPassword } = useAppNavigate();

  return (
    <div className={findEmailPasswordStyles.findEmailCompleteButtonsContainer}>
      <button
        className={findEmailPasswordStyles.findEmailCompleteButton}
        onClick={goToLogin}
      >
        로그인 하러가기
      </button>

      {!isFindPasswordComplete && (
        <button
          className={findEmailPasswordStyles.findEmailCompleteButton}
          onClick={goToFindEmailPassword}
        >
          비밀번호 찾기
        </button>
      )}
    </div>
  );
};

export default FindEmailCompleteButtons;
