import style from "./RankingSearchStyle.module.scss";
import { useState } from "react";

const KeywordCountBox = () => {
  const [monthlySearchCount, setMonthlySearchCount] = useState(0);
  const [monthlyBlogCount, setMonthlyBlogCount] = useState(0);
  return (
    <div className={style.keywordCountBoxContainer}>
      <span className={style.dateRange}>
        2024.08.10 ~ 2024.09.10 키워드 검색결과
      </span>
      <div className={style.keywordCountBox}>
        <div className={style.monthlySearchCount}>
          <span>월간 검색수</span>
          <span className={style.count}>{monthlySearchCount}</span>
        </div>
        <div className={style.divider}></div>
        <div className={style.monthlyBlogCount}>
          <span>월간 블로그 게시물 수</span>
          <span className={style.count}>{monthlyBlogCount}</span>
        </div>
      </div>
    </div>
  );
};

export default KeywordCountBox;
