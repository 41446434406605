import MypageStyle from "./MymbershipStyle.module.scss";
import PropTypes from "prop-types";
import { MEMBERSHIP_DATA } from "../../../utils/constants/membership";
import { setMembershipDataKeyForUpgrade } from "../../../utils/functions/membership";

const UpgradeMembershipCard = ({
  subscriptionType,
  isShowUpgradeMembershipInfo = false,
}) => {
  // 결제 버튼 텍스트 설정

  const payButtonText = subscriptionType === "free" ? "결제" : "변경";
  const setMembershipKey = () => {
    if (isShowUpgradeMembershipInfo) {
      return subscriptionType;
    } else {
      return setMembershipDataKeyForUpgrade(subscriptionType);
    }
  };

  // 구독 타입에 따라 멤버십 데이터 키 결정
  const membershipDataKey = setMembershipKey();
  console.log(membershipDataKey);

  return (
    <div className={MypageStyle.upgradeMembershipCard}>
      <div className={MypageStyle.payButton}>{payButtonText}</div>

      <div
        className={`${MypageStyle.membershipInfoBox} ${
          isShowUpgradeMembershipInfo ? MypageStyle.isShowUpgradeStyle : ""
        }`}
      >
        <div className={MypageStyle.leftContent}>
          <span className={MypageStyle.membershipType}>
            {MEMBERSHIP_DATA[membershipDataKey].membershipName}
          </span>
        </div>

        <div className={MypageStyle.line}></div>

        <div className={MypageStyle.rightContent}>
          <span>실시간 순위분석</span>
          <span>
            등록가능한 플레이스
            {MEMBERSHIP_DATA[membershipDataKey].placeCount}건
          </span>

          {subscriptionType !== "premium" ? (
            <span>
              플레이스 별 등록가능한 키워드 <br />
              {MEMBERSHIP_DATA[membershipDataKey].keywordCount}건(총{" "}
              {MEMBERSHIP_DATA[membershipDataKey].keywordCount *
                MEMBERSHIP_DATA[membershipDataKey].placeCount}
              개)
            </span>
          ) : (
            <span>
              키워드{" "}
              {MEMBERSHIP_DATA[membershipDataKey].keywordCount *
                MEMBERSHIP_DATA[membershipDataKey].placeCount}
              개를 원하는
              <br />
              플레이스에 자유롭게 등록
            </span>
          )}

          {subscriptionType !== "basic" ? (
            <span>
              순위로그+세부로그 분석 <br />
              (저장,리뷰,블로그 수)
            </span>
          ) : null}

          <span>
            순위 알림 수 {MEMBERSHIP_DATA[membershipDataKey].alarmCount}건
          </span>
        </div>
      </div>
    </div>
  );
};

UpgradeMembershipCard.propTypes = {
  subscriptionType: PropTypes.string.isRequired,
};

export default UpgradeMembershipCard;
