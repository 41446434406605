import { useState } from "react";
import MypageStyle from "./MymbershipStyle.module.scss";
import { ReactComponent as CardIcon } from "../../../assets/icons/card_icon.svg";
import { ReactComponent as NaverPayIcon } from "../../../assets/icons/naver_pay_icon.svg";
import { ReactComponent as NaverPayDisableIcon } from "../../../assets/icons/naver_pay_disable_icon.svg";
const PayButtons = ({ isChangePayInfo = false }) => {
  const notActiveColor = "#ABABAB";
  const cardActiveColor = "#3182F6";
  const naverPayActiveColor = "#03cf5d";
  const [cardButtonActive, setCardButtonActive] = useState(false);
  const [naverPayButtonActive, setNaverPayButtonActive] = useState(false);

  const handleCardButtonClick = () => {
    console.log("cardButtonActive", cardButtonActive);
    setCardButtonActive(true);
    setNaverPayButtonActive(false);
  };

  const handleNaverPayButtonClick = () => {
    console.log("naverPayButtonActive", naverPayButtonActive);
    setCardButtonActive(false);
    setNaverPayButtonActive(true);
  };
  return (
    <div className={MypageStyle.payButtons}>
      <button
        className={`${MypageStyle.cardButton} ${
          cardButtonActive ? MypageStyle.active : ""
        }`}
        onClick={handleCardButtonClick}
      >
        <CardIcon />
        <span>신용카드</span>
      </button>
      <button
        className={`${MypageStyle.naverPayButton} ${
          naverPayButtonActive ? MypageStyle.active : ""
        }`}
        onClick={handleNaverPayButtonClick}
      >
        {naverPayButtonActive ? <NaverPayIcon /> : <NaverPayDisableIcon />}
        <span>결제하기</span>
      </button>
    </div>
  );
};

export default PayButtons;
