import MypageStyle from "./MymbershipStyle.module.scss";
import UsingMembershipCard from "./UsingMembershipCard";
import UpgradeMembershipCard from "./UpgradeMembershipCard";
import { MEMBERSHIP_DATA } from "../../../utils/constants/membership";
import { setMembershipDataKey } from "../../../utils/functions/membership";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getMembershipData } from "../../../utils/api/membership";
const MembershipCardList = () => {
  console.log("🚀 ~ MembershipCardList ~ MembershipCardList:");
  const dispatch = useDispatch();

  const membershipData = useSelector((state) => state.membership);

  const membershipDataKey = setMembershipDataKey(
    membershipData.subscription_type
  );

  useEffect(() => {
    console.log("🚀 ~ MembershipCardList ~ useEffect:");
  }, []);

  if (membershipData.subscription_type === null) {
    console.log("🚀 ~ MembershipCardList ~ MembershipCardList loading:");

    return <div>Loading...</div>;
  }

  return (
    console.log("🚀 ~ MembershipCardList ~ MembershipCardList return:"),
    (
      <div className={MypageStyle.membershipCardList}>
        <div className={MypageStyle.membershipCardListBox}>
          <div className={MypageStyle.usingMembershipBox}>
            {
              // 무료가 아니고 무료 이용기간이 아닐 때 '이용 중인 멤버십' 표시
              membershipData.subscription_type !== "free" &&
              membershipData.is_free_trial === false ? (
                <span className={MypageStyle.membershipText}>
                  이용 중인 멤버십
                </span>
              ) : null
            }

            <UsingMembershipCard
              isSubscribe={membershipData.subscription_type !== "free"}
              // free 회원은 구독중 아님
              isFreeTrial={membershipData.is_free_trial}
              subscriptionType={membershipData.subscription_type}
              startDate={membershipData.sub_str_date}
              endDate={membershipData.sub_end_date}
            />
          </div>
          {/* invisible 처리 : 무료 이용기간 중 or premium 회원일 경우 */}
          {/* 프리미엄이 아니고 무료 이용기간이 아닐 때 변경 멤버십 표시 */}
          {!membershipData.is_free_trial &&
          membershipData.subscription_type !== "premium" ? (
            <div className={MypageStyle.changeMembershipBox}>
              {membershipData.subscription_type !== "free" ? (
                <span className={MypageStyle.membershipText}>추천 멤버십</span>
              ) : null}
              <UpgradeMembershipCard
                subscriptionType={membershipData.subscription_type}
              />
            </div>
          ) : null}
        </div>

        {
          //
          membershipData.is_free_trial === true ||
          membershipData.subscription_type === "free" ? (
            <span className={MypageStyle.freeTrialText}>
              25,900원으로 구독하여 혜택을 받아보세요!
            </span>
          ) : null
        }

        {membershipData.subscription_type !== "premium" ? (
          <div className={MypageStyle.textBox}>
            <span className={MypageStyle.membershipCostText}>
              {"최종 월별 결제금액 "}
              <span className={MypageStyle.membershipCost}>
                {MEMBERSHIP_DATA[membershipDataKey].membershipCost}원
              </span>
            </span>
            <span>
              *맴버십을 변경할 경우 변경 및 결제가 즉시 적용됩니다. <br />
            </span>
            <span>
              *이전 멤버쉽은 이용 기간 시작 달 기준으로 일자를 측정하여 <br />
              사용하지 않은 일자를 계산해 카드사로 2-5일 이내로 환불됩니다.
              <br />
            </span>
            <span>
              *모든 멤버쉽은 월 구독 상품으로 중도해지가 되지 않을 시 매달
              정기결제가 이루어집니다.
            </span>
          </div>
        ) : null}
      </div>
    )
  );
};

export default MembershipCardList;
