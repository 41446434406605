import signupStyle from "./SignUpStyle.module.scss";
import globalStyle from "../../global.module.scss";
import { useAppNavigate } from "../../utils/navigate/navigate";
function SignUpCompleteButtons() {
  const { goToLogin } = useAppNavigate();
  return (
    <div className={signupStyle.signupCompleteContainer}>
      <button className={globalStyle.whiteButton} onClick={goToLogin}>
        로그인 하러가기
      </button>
    </div>
  );
}

export default SignUpCompleteButtons;
