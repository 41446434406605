import styles from "./CommonStyle.module.scss";

const Switch = ({ isOn, setIsOn }) => {
  const handleSwitch = () => {
    setIsOn(!isOn);
  };

  return (
    <div className={styles.switchContainer}>
      <label className={styles.switch}>
        <input type="checkbox" onChange={handleSwitch} />
        <span className={styles.slider} />
      </label>

      <span className={styles.toggleText}>저장/리뷰/블로그</span>
    </div>
  );
};

export default Switch;
