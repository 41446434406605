import styles from "./HomeStyle.module.scss";
import firstSlideImage from "../../assets/images/robot1.png";
import secondSlideImage from "../../assets/images/robot2.png";
const FirstSlideItem = () => {
  return (
    <div className={styles.firstSlideBox}>
      <img
        className={styles.firstSlideImage}
        src={firstSlideImage}
        alt="firstSlideImage"
      />

      <img
        className={styles.secondSlideImage}
        src={secondSlideImage}
        alt="secondSlideImage"
      />
      <section className={styles.firstSlideTextarea}>
        <span>
          <span title="플레이스의 핵심 키워드 전략">
            <span className={styles.bold}>플레이스</span>
            <span>의 핵심 </span>
            <span className={styles.bold}>키워드 </span>
            <span>전략</span>
          </span>
          <br />
        </span>
        <span className={styles.logoText} title="캐치랭크">
          캐치랭크
        </span>
      </section>
    </div>
  );
};

export default FirstSlideItem;
