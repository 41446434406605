// src/components/UI/Input.js
import MembershipStyle from "./AccountStyle.module.scss";
import PropTypes from "prop-types";

const Input = ({
  label,
  type = "text",
  name,
  value,
  onChange,
  placeholder,
  errorMessage,
  isValid,
  pattern,
  required = false,
  disabled = false,
  maxLength,
}) => {
  return (
    <div className={MembershipStyle.inputContainer}>
      {label && <label htmlFor={name}>{label}</label>}
      <input
        type={type}
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        pattern={pattern}
        className={
          isValid ? MembershipStyle.validInput : MembershipStyle.invalidInput
        }
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        maxLength={maxLength}
      />
      {!isValid && (
        <span className={MembershipStyle.error}>{errorMessage}</span>
      )}
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  pattern: PropTypes.string,
  isValid: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};
export default Input;
