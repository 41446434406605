import style from "./DetailRegisteredPlaceStyle.module.scss";
import { map, chunk } from "lodash";
import React, { useEffect, useState } from "react"; // React와 useEffect, useState를 import

const RankingTable = ({
  id,
  key,
  keyword,
  storeName,
  headRank,
  headRankDate,
  rankItems,
  isAlarmActive,
  isVisibleMore,
  toggleAlarm,
}) => {
  const [showNotification, setShowNotification] = useState(false);
  useEffect(() => {
    if (!isAlarmActive) {
      setShowNotification(true);
      const timer = setTimeout(() => {
        setShowNotification(false);
      }, 2000); // 3초 후에 알림을 자동으로 숨깁니다.
      return () => clearTimeout(timer); // 컴포넌트 언마운트 시 타이머 정리
    }
  }, [isAlarmActive]); // isAlarmActive가 변경될 때마다 이 효과를 실행

  return (
    <div>
      <table key={key} className={style.table}>
        <thead>
          <tr>
            <th colspan="10" className={style.mainHeader}>
              <div className={style.headerBox}>
                <div className={style.headerLeft}>
                  <div className={style.keywordBox}>
                    <span className={style.keyword}>{keyword}</span>
                    <div className={style.divider}></div>
                    <span>{storeName}</span>
                  </div>
                  <div className={style.keywordBox}>
                    <span>{headRankDate}</span>
                    <div className={style.divider}></div>

                    <span className={style.headRank}>{headRank}</span>
                  </div>
                </div>
                {/* 알람 아이콘 (활성화 상태에 따라 변경) */}

                <div
                  className={`${style.alarmIcon} ${
                    isAlarmActive ? style.active : ""
                  }`}
                  onClick={toggleAlarm}
                >
                  {showNotification && (
                    <div className={style.notification}>알림이 꺼졌어요.</div>
                  )}
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {/* 테이블 본문: rankItems 배열을 10개씩          나누어 각 행에 표시 */}
          {chunk(rankItems, isVisibleMore ? 8 : 10).map(
            (chunkedItems, index) => (
              <tr
                key={index}
                className={`${style.tableRow} ${
                  isVisibleMore ? style.moreVisibleTableRow : ""
                }`}
              >
                {map(chunkedItems, (item, index) => (
                  <td
                    key={item.id}
                    className={`${style.tableItemBox} ${
                      isVisibleMore ? style.moreVisibletableItemBox : ""
                    }`}
                  >
                    {/* 날짜 및 순위 정보 표시 */}
                    <span>{item.date}</span>
                    {isVisibleMore ? (
                      <>
                        <div className={style.tagAndRankBox}>
                          <span className={style.rankTag}>순위</span>
                          <span className={style.keywordRank}>
                            {item.keywordRank}위
                          </span>
                        </div>
                        <div className={style.tagAndRankBox}>
                          <span className={style.saveTag}>저장</span>
                          <span className={style.keywordRank}>
                            {item.saves}
                          </span>
                        </div>

                        <div className={style.tagAndRankBox}>
                          <span className={style.visitorReviewTag}>리뷰</span>
                          <span className={style.keywordRank}>
                            {item.visitorReviews}
                          </span>
                        </div>
                        <div className={style.tagAndRankBox}>
                          <span className={style.blogReviewTag}>블로그</span>
                          <span className={style.keywordRank}>
                            {item.blogReviews}
                          </span>
                        </div>
                      </>
                    ) : (
                      <span className={style.keywordRank}>
                        {item.keywordRank}위
                      </span>
                    )}
                  </td>
                ))}
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

export default RankingTable;
