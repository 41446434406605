import { useNavigate } from "react-router-dom";
import URL_PATH from "../constants/urlPath";

export function useAppNavigate() {
  const navigate = useNavigate();

  const goToHome = () => navigate(URL_PATH.home, { replace: true });

  const goToLogin = (isReplace = false) =>
    navigate(URL_PATH.login, { replace: isReplace });

  const goToEmailLogin = (isReplace = false) =>
    navigate(URL_PATH.emailLogin, { replace: isReplace });

  const goToSignup = (isReplace = false) =>
    navigate(URL_PATH.signup, { replace: isReplace });

  const goToSignupComplete = (isReplace = true) =>
    navigate(URL_PATH.signupComplete, { replace: isReplace });

  const goToFindEmailPassword = (isReplace = false) =>
    navigate(URL_PATH.findEmailPassword, { replace: isReplace });

  const goToFindEmailComplete = (isReplace = true) =>
    navigate(URL_PATH.findEmailComplete, { replace: isReplace });

  const goToFindPasswordComplete = (isReplace = true) =>
    navigate(URL_PATH.findPasswordComplete, { replace: isReplace });

  const goToRankingSearch = (isReplace = true) => {
    navigate(URL_PATH.rankingSearch, { replace: isReplace });
  };

  const goToRankingSearchResult = ({
    isReplace = false,
    placeKeyword,
    placeNameOrUrl,
  }) => {
    console.log("🚀 ~ useAppNavigate ~ goToRankingSearchResult:");

    navigate(
      URL_PATH.rankingSearchResult
        .replace(":placeKeyword", placeKeyword)
        .replace(":placeNameOrUrl", placeNameOrUrl),
      { replace: isReplace }
    );
  };

  const goToRegisteredPlace = (isReplace = true) =>
    navigate(URL_PATH.registeredPlace, { replace: isReplace });

  const goToRegisteredPlaceDetail = ({ placeId, isReplace = false }) =>
    navigate(URL_PATH.registeredPlaceDetail.replace(":placeId", placeId), {
      replace: isReplace,
    });

  const goToMembership = (isReplace = true) =>
    navigate(URL_PATH.membership, { replace: isReplace });

  const goToMyMembership = (isReplace = true) =>
    navigate(URL_PATH.myMembership, { replace: isReplace });

  const goToModifyMemberInfo = (isReplace = true) =>
    navigate(URL_PATH.modifyMemberInfo, { replace: isReplace });

  const goToCompleteDeleteAccount = (isReplace = true) =>
    navigate(URL_PATH.completeDeleteAccount, { replace: isReplace });
  const goToExitSurvey = (isReplace = true) =>
    navigate(URL_PATH.exitSurvey, { replace: isReplace });

  const goToFirstLoginBenefits = (isReplace = true) =>
    navigate(URL_PATH.firstLoginBenefits, { replace: isReplace });

  return {
    goToHome,
    goToLogin,
    goToEmailLogin,
    goToSignup,
    goToSignupComplete,
    goToFindEmailPassword,
    goToFindEmailComplete,
    goToFindPasswordComplete,
    goToRankingSearch,
    goToRankingSearchResult,
    goToRegisteredPlace,
    goToRegisteredPlaceDetail,
    goToMembership,
    goToMyMembership,
    goToModifyMemberInfo,
    goToCompleteDeleteAccount,
    goToExitSurvey,
    goToFirstLoginBenefits,
  };
}
