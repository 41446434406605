import styles from "./RankingSearchStyle.module.scss";
import RankingSearchInput from "./RankingSearchInput";
import { useState } from "react";
import { ReactComponent as SearchIcon } from "./../../assets/icons/search_icon.svg"; // SVG 파일을 리액트 컴포넌트로 가져옵니다.
import { useAppNavigate } from "../../utils/navigate/navigate";

const RankingSearchForm = () => {
  const { goToRankingSearchResult } = useAppNavigate();
  const [placeKeyword, setPlaceKeyword] = useState({
    placeKeyword: "",
    placeNameOrUrl: "",
  });
  // formValid 상태를 사용하여 폼의 전체 유효성을 관리합니다.
  const [formValid, setFormValid] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPlaceKeyword((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    console.log("🚀 ~ handleSubmit ~ handleSubmit:");
    // e.preventDefault();
    goToRankingSearchResult({
      placeKeyword: placeKeyword.placeKeyword,
      placeNameOrUrl: placeKeyword.placeNameOrUrl,
    });
  };

  return (
    <div className={styles.rankingSearchFormContainer}>
      <div className={styles.inputContainer}>
        <RankingSearchInput
          label="플레이스 키워드"
          name="placeKeyword"
          value={placeKeyword.placeKeyword}
          onChange={handleChange}
          placeholder="지역+카테고리로 검색해보세요.(ex. 이태원맛집, 강남카페)"
        />

        <RankingSearchInput
          label="이름 또는 플레이스 URL"
          name="placeNameOrUrl"
          value={placeKeyword.placeNameOrUrl}
          onChange={handleChange}
          placeholder="이름 또는 플레이스 URL 링크를 입력해보세요."
        />
      </div>

      <p className={styles.rankingSearchFormNote}>
        *음식점 카테고리 외엔 저장 수를 제공되지 않습니다.
        <br />
        *이름으로 검색 시 키워드를 지역+카테고리로 입력해야 업장 순위를 더
        정확하게 파악할 수 있어요.
      </p>

      <button
        className={styles.rankingSearchFormButton}
        onClick={() => handleSubmit()}
        disabled={formValid}
      >
        검색
        <SearchIcon />
      </button>
    </div>
  );
};

export default RankingSearchForm;
