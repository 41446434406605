// src/components/ConfirmDialog.js

import CommonStyle from "./CommonStyle.module.scss";
import PropTypes from "prop-types";
const ConfirmDialog = ({
  open,
  onClose,
  onConfirm,
  children,
  title,
  cancelText,
  confirmText,
}) => {
  if (!open) return null;

  return (
    <div className={CommonStyle.confirmModalOverlay}>
      <div className={CommonStyle.confirmModal}>
        <div className={CommonStyle.confirmModalContent}>
          <h2 className={CommonStyle.confirmModalTitle}>{title}</h2>
          {children ? <div>{children}</div> : null}
          <div className={CommonStyle.confirmModalButton}>
            <button
              onClick={() => {
                onConfirm();
                onClose();
              }}
            >
              {confirmText}
            </button>
            <button onClick={onClose}>{cancelText}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  children: PropTypes.node,
  title: PropTypes.node.isRequired,
  cancelText: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
};

export default ConfirmDialog;
