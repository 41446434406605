import MembershipStyle from "./MyMembershipStyle.module.scss";
import MembershipCard from "./MembershipCard";
import { map } from "lodash";
import { MEMBERSHIP_DATA } from "../../utils/constants/membership";

function MembershipCardList({ onEventTrigger }) {
  const membershipData = MEMBERSHIP_DATA;

  return (
    <div className={MembershipStyle.membershipCardListBox}>
      {map(membershipData, (membership) => (
        <MembershipCard
          membershipName={membership.membershipName}
          isSubscribed={membership.isSubscribed}
          membershipCost={membership.membershipCost}
          placeCount={membership.placeCount}
          keywordCount={membership.keywordCount}
          alarmCount={membership.alarmCount}
          onEventTrigger={onEventTrigger}
        />
      ))}
    </div>
  );
}

export default MembershipCardList;
