import style from "./DetailRegisteredPlaceStyle.module.scss";
import { useState } from "react";
const PlaceInfoFetcher = () => {
  const [storeData, setStoreData] = useState({
    id: 1,
    image: "https://picsum.photos/200/300",
    name: "가게명1",
    category: "카테고리1",
    visitorReviews: 120,
    blogReviews: 30,
    saves: 50,
    mapRank: 1,
    pcRankPage: 1,
    pcRank: 10,
    mobileRankPage: 3,
    mobileRank: 2,
  });

  return (
    <div className={style.placeInfoFetcherBox}>
      <img
        src={storeData.image}
        alt={storeData.name}
        className={style.registerStoreCardImage}
      />

      <div className={style.placeInfoFetcherContent}>
        <div className={style.placeInfoFetcherContentHeader}>
          <span className={style.name}>{storeData.name}</span>
          <span className={style.category}>{storeData.category}</span>
        </div>

        <div className={style.placeInfoFetcherContentMiddle}>
          <div className={style.placeInfoFetcherContentMiddleItem}>
            방문자 리뷰
            {storeData.visitorReviews && (
              <span>{storeData.visitorReviews}</span>
            )}
          </div>

          <div className={style.placeInfoFetcherContentMiddleItem}>
            블로그 리뷰
            {storeData.blogReviews && <span>{storeData.blogReviews}</span>}
          </div>

          <div className={style.placeInfoFetcherContentMiddleItem}>
            저장수
            {storeData.saves && <span>{storeData.saves}</span>}
          </div>
        </div>

        <div className={style.placeInfoFetcherContentFooter}>
          <span className={style.mapRank}>{storeData.mapRank}위</span>
          <span>
            PC : {storeData.pcRankPage}페이지 / {storeData.pcRank}위
          </span>
          <span>
            Mobile : {storeData.mobileRankPage}페이지 / {storeData.mobileRank}위
          </span>
        </div>
      </div>
    </div>
  );
};

export default PlaceInfoFetcher;
