import { useState } from "react";

import styles from "./RankingSearch.module.scss";
import RankingSearchForm from "../../components/RankingSearch/RankingSearchForm";
import Loader from "../../components/Common/Loader";

const RankingSearch = () => {
  // const [loader, setLoader] = useState(true);

  // if (loader) return <Loader />;
  return (
    <div className={styles.rankingSearchContainer}>
      <div className={styles.rankingSearchTextContainer}>
        <h1 className={styles.rankingSearchTitle}>
          네이버 플레이스에서 실시간 순위,
          <br />
          바로 알아보세요!
        </h1>

        <h2 className={styles.rankingSearchSubtitle}>
          키워드 데이터를 분석하여 마케팅 콘텐츠의 유입을 극대화, 최적의
          시너지를 경험해보세요.
        </h2>
      </div>

      <div className={styles.rankingSearchInputContainer}>
        <RankingSearchForm />
      </div>
    </div>
  );
};

export default RankingSearch;
