import React, { useState, useEffect } from "react";
import InputField from "./InputField";
import Checkbox from "./CheckBox";
import signUpStyles from "./SignUpStyle.module.scss";
import { useAppNavigate } from "../../utils/navigate/navigate";
function SignupForm() {
  const { goToSignupComplete } = useAppNavigate();
  // formData 상태를 사용하여 폼 데이터를 관리합니다.
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    name: "",
    birthDate: "",
    phoneNumber: "",
    phoneAuth: "",
    termsAgreed: false,
    ageConsent: false, // 만 14세 이상 동의
    termsOfService: false, // 서비스 이용약관 동의
    privacyPolicy: false, // 개인정보 수집/이용 동의
    marketing: false, // 마케팅 동의
  });

  // formValid 상태를 사용하여 폼의 전체 유효성을 관리합니다.
  const [formValid, setFormValid] = useState(false);

  // 휴대폰 인증 상태를 관리합니다.
  const [isPhoneAuthSent, setPhoneAuthSent] = useState(false);
  const [authCode, setAuthCode] = useState("");
  const [timer, setTimer] = useState(240);
  const [authStatus, setAuthStatus] = useState("");

  // formData가 변경될 때마다 유효성 검사를 수행합니다.
  useEffect(() => {
    const isValid = validateForm();
    setFormValid(isValid);
  }, [formData]);

  // 입력 필드 값이 변경될 때 호출되는 함수입니다.
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData((prev) => ({
      ...prev,
      [name]: newValue,
    }));
  };

  // "모두 동의" 체크박스를 관리하는 함수입니다.
  const handleAllAgree = (checked) => {
    setFormData((prev) => ({
      ...prev,
      termsAgreed: checked,
      ageConsent: checked,
      termsOfService: checked,
      privacyPolicy: checked,
      marketing: checked,
    }));
  };

  //!TODO : 필수로 체크해야하는 체크 박스 유효성 검사 로직 추가하기
  // 폼의 유효성을 검사하는 함수입니다.
  const validateForm = () => {
    // 비밀번호 조건 검사 (영문자+숫자 조합, 최소 6-12자)
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,12}$/;
    const isPasswordValid = passwordRegex.test(formData.password);
    // 비밀번호 일치 검사
    const isPasswordMatch = formData.password === formData.confirmPassword;
    // 필수 입력 필드 검사
    const isRequiredFilled =
      formData.email &&
      formData.name &&
      formData.birthDate &&
      formData.phoneNumber &&
      formData.termsAgreed;
    return isPasswordValid && isPasswordMatch && isRequiredFilled;
  };

  // 폼 제출 시 호출되는 함수입니다.
  const handleSubmit = (e) => {
    e.preventDefault();
    goToSignupComplete();

    // 여기에 회원가입 처리 로직을 추가할 수 있습니다.
  };

  // 인증번호 전송 버튼 클릭 이벤트
  const handleSendAuthCode = () => {
    setPhoneAuthSent(true);
    // TODO: 백엔드에 인증번호 요청 로직 구현
    // 예: sendAuthCodeAPI(formData.phoneNumber).then(response => ...)
    startTimer();
  };

  // 타이머 시작
  const startTimer = () => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(countdown);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  const handleVerifyAuthCode = () => {
    // TODO: 백엔드에 인증번호 검증 요청 로직 구현
    // 예: verifyAuthCodeAPI(formData.phoneNumber, authCode).then(response => ...)
    setAuthStatus("인증번호가 일치하지 않습니다.");
  };

  return (
    <form onSubmit={handleSubmit} className={signUpStyles.signupFormContainer}>
      <InputField
        label="이메일"
        type="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        placeholder="이메일(아이디)을 입력해 주세요."
      />
      <div className={signUpStyles.passwordContainer}>
        <InputField
          label="비밀번호"
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          placeholder="비밀번호 (영문,소문자와 숫자포함 8~12자)"
          pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,12}$"
        />
        <InputField
          type="password"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          placeholder="비밀번호를 한번 더 입력해 주세요."
          pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,12}$"
        />
      </div>
      <InputField
        label="이름"
        name="name"
        value={formData.name}
        onChange={handleChange}
        placeholder="이름을 입력해 주세요."
        pattern="^[가-힣]{2,5}$"
      />
      <InputField
        label="생년월일"
        type="date" // 여기서 type을 text로 설정합니다.
        name="birthDate"
        value={formData.birthDate}
        onChange={handleChange}
        placeholder="YYYY.MM.DD"
        pattern="^[0-9]{4}.[0-9]{2}.[0-9]{2}$"
      />
      <div className={signUpStyles.phoneNumberBox}>
        <InputField
          label="휴대폰 번호"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          placeholder="숫자만 입력해 주세요."
          pattern="^[0-9]{10,11}$"
          disabled={isPhoneAuthSent}
          showDatePicker={true} // DatePicker를 표시하기 위한 prop 추가
        />

        <button
          className={signUpStyles.phoneAuthButton}
          onClick={handleSendAuthCode}
          disabled={
            formData.phoneNumber.length !== 10 &&
            formData.phoneNumber.length !== 11
          }
        >
          {/* TODO : 발송 로직이 성공적으로 실행되면 버튼 비활성화 하기 */}
          인증번호 발송
        </button>
      </div>
      {isPhoneAuthSent && (
        <div className={signUpStyles.authTextBox}>
          <div className={signUpStyles.phoneNumberBox}>
            <InputField
              label="인증번호"
              name="authCode"
              value={authCode}
              onChange={(e) => setAuthCode(e.target.value)}
              placeholder="인증번호를 입력해 주세요."
            />
            <button
              onClick={handleVerifyAuthCode}
              className={signUpStyles.phoneAuthButton}
            >
              인증번호 확인
            </button>
          </div>
          {timer > 0 ? (
            <span className={signUpStyles.authTimer}>{` ${Math.floor(timer / 60)
              .toString()
              .padStart(2, "0")}:${(timer % 60)
              .toString()
              .padStart(2, "0")}`}</span>
          ) : (
            <span>인증번호가 일치하지 않습니다.</span>
          )}
          {authStatus && <p>{authStatus}</p>}
        </div>
      )}
      <div className={signUpStyles.agreementContainer}>
        <Checkbox
          label="모두 동의합니다."
          isChecked={formData.termsAgreed}
          onChange={handleChange}
        />
        <div className={signUpStyles.agreementDivider}></div>
        <Checkbox
          label="만 14세 이상입니다."
          isChecked={formData.termsAgreed}
          onChange={handleChange}
          isRequired
        />{" "}
        <Checkbox
          label={
            <>
              <strong>서비스 이용약관</strong>에 동의합니다
            </>
          }
          isChecked={formData.termsOfService}
          onChange={handleChange}
          isRequired={true}
        />{" "}
        <Checkbox
          label={
            <>
              <strong>개인정보 수집/이용</strong>에 동의합니다
            </>
          }
          isChecked={formData.privacyPolicy}
          onChange={handleChange}
          isRequired={true}
        />
        <Checkbox
          label={
            <>
              <strong>광고성 정보 수신 및 마케팅 활용</strong>에 동의합니다
              (선택)
            </>
          }
          isChecked={formData.marketing}
          onChange={handleChange}
        />
      </div>
      <button
        disabled={formValid}
        className={signUpStyles.signupButton}
        type="submit"
        onClick={handleSubmit}
      >
        회원가입
      </button>
    </form>
  );
}

export default SignupForm;
