import styles from "./MyMembership.module.scss";
import MembershipCardList from "../../../components/Mypage/MyMembership/MymbershipCardList.js";
import PaymentFormBox from "../../../components/Mypage/MyMembership/PaymentFormBox.js";
import { useSelector } from "react-redux";
const MyMebership = () => {
  const membershipData = useSelector((state) => state.membership);

  if (membershipData.subscription_type === null) {
    console.log("🚀 ~ MembershipCardList ~ MembershipCardList loading:");

    return <div>Loading...</div>;
  }

  return (
    <div className={styles.myMembershipBox}>
      <h1 className={styles.title}>나의 멤버십</h1>

      <div className={styles.membershipCardList}>
        <MembershipCardList />
      </div>

      <div className={styles.paymentFormBox}>
        <PaymentFormBox />
      </div>
    </div>
  );
};

export default MyMebership;
