import { useState } from "react";
import MembershipStyle from "./Membership.module.scss";
import MembershipCardList from "../../components/Membership/MembershipCardList";
import UpgradeMembershipCard from "../../components/Mypage/MyMembership/UpgradeMembershipCard";
import { MEMBERSHIP_DATA } from "../../utils/constants/membership";
import PayGuideBox from "../../components/Membership/PayGuideBox";
import PaymentFormBox from "../../components/Mypage/MyMembership/PaymentFormBox";

function Membership() {
  const [showMembershipInfo, setShowMembershipInfo] = useState(null);

  const handleEvent = (data) => {
    console.log(data);
    const lowerCaseData = data.toLowerCase();
    setShowMembershipInfo(() => lowerCaseData);
  };

  return (
    <div className={MembershipStyle.membershipBox}>
      <div className={MembershipStyle.textItems}>
        <h1 className={MembershipStyle.title}>플로그의 멤버십 혜택</h1>
      </div>

      <div className={MembershipStyle.contentBox}>
        <MembershipCardList onEventTrigger={handleEvent} />
      </div>

      {showMembershipInfo && (
        <div className={MembershipStyle.membershipInfoBox}>
          <div>
            <UpgradeMembershipCard
              subscriptionType={showMembershipInfo}
              isShowUpgradeMembershipInfo={true}
            />

            <span className={MembershipStyle.payText}>
              {MEMBERSHIP_DATA[showMembershipInfo].membershipCost}원으로
              구독하여 혜택을 받아보세요!
            </span>
          </div>

          <PayGuideBox subscription_type={showMembershipInfo} />
          <PaymentFormBox />
        </div>
      )}
    </div>
  );
}

export default Membership;
