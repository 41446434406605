import styles from "./layout.module.scss";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer/Footer";
function FooterLayout() {
  return (
    <div className={styles.layoutBox}>
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}

export default FooterLayout;
