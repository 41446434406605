import MypageStyle from "./MymbershipStyle.module.scss";
import { useState } from "react";
import PayButtons from "./PayButtons";

const PaymentFormBox = ({ isShowUpgradeMembershipInfo }) => {
  const [paymentInfo, setPaymentInfo] = useState(
    "우리 가득한(체크) **** **** **** 0000"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [showPayButtons, setShowPayButtons] = useState(false);
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={MypageStyle.paymentFormBox}>
      {paymentInfo === null ? (
        // TODO: free 멤버십일 때 결제 수단 파타 아예 안보였다가 버튼 클릭시 보이게 하기
        <div className={MypageStyle.noRegisterCardBox}>
          <div className={MypageStyle.textBox}>
            <span className={MypageStyle.title}>결제 수단</span>
            <span className={MypageStyle.subtitle}>
              결제수단을 선택해주세요.
            </span>
          </div>
          <PayButtons />
        </div>
      ) : (
        <div className={MypageStyle.registeredCardBox}>
          <div className={MypageStyle.registeredCardBoxHeader}>
            <span className={MypageStyle.title}>등록된 카드</span>
            <div className={MypageStyle.registerCardInfoBox}>
              {paymentInfo}
              {/* 멤버십 안내 페이지에서는 변경 버튼 보이지 않음  -> 
              멤버십 안내에서는 지불 카드 변경 불가능
              마이페이지에서만  카드 변경 가능*/}
              {isShowUpgradeMembershipInfo ? (
                <button onClick={() => setShowPayButtons(true)}>변경</button>
              ) : null}
            </div>
          </div>

          {showPayButtons ? (
            <div className={MypageStyle.changePayInfoBox}>
              <div className={MypageStyle.textBox}>
                <span className={MypageStyle.title}>결제 수단 변경</span>
                <span className={MypageStyle.subtitle}>
                  결제수단을 선택해주세요.
                </span>
              </div>
              <PayButtons isChangePayInfo={true} />
              <span className={MypageStyle.changePayInfoText}>
                *결제수단 변경시, 다음 결제일 부터 변경한 결제 수단으로 <br />
                결제됩니다. 실제 바로 결제가 일어나지 않으니 안심하셔도 됩니다.
              </span>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default PaymentFormBox;
