import SignupForm from "../../components/SignUp/SignupForm"; // 경로는 실제 프로젝트 구조에 맞게 조정
import signupStyle from "./SignUp.module.scss";
import smileIcon from "../../assets/icons/smile_icon.svg";
import SignUpCompleteButtons from "../../components/SignUp/SignUpCompleteButtons";
function SignupPage() {
  return (
    <div className={signupStyle.signupCompleteContainer}>
      <img className={signupStyle.signupCompleteIcon} src={smileIcon}></img>
      <div className={signupStyle.signupCompleteTitle}>
        플로그의 회원이 되신걸 환영합니다.
      </div>
      <div className={signupStyle.signupCompleteSubtitle}>
        한눈에 쏙! 편하고 쉽게 이용해 보세요!
      </div>

      <div className={signupStyle.completeButtonsContainer}>
        <SignUpCompleteButtons />
      </div>
    </div>
  );
}

export default SignupPage;
