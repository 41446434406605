import ErrorStyle from "./error.module.scss";
const NotFound = () => {
  return (
    <div className={ErrorStyle.notFound}>
      <h1 className={ErrorStyle.notFoundTitle}>404</h1>
      <h2 className={ErrorStyle.subtitle}> 페이지를 찾을 수 없습니다.</h2>
      <span className={ErrorStyle.notFoundSubTitle}>
        찾을 수 없는 페이지 입니다. <br />
        요청하신 페이지가 사라졌거나, 잘못된 경로를 사용하셨습니다.
      </span>

      <span className={ErrorStyle.copyright}>@amplify AII Right Reserved</span>
    </div>
  );
};

export default NotFound;
