import styles from "./MyMembership.module.scss";
import { useAppNavigate } from "../../../utils/navigate/navigate";
const ExitSurvey = () => {
  const { goToCompleteDeleteAccount } = useAppNavigate();
  const reasons = [
    "가격이 부담 돼요.",
    "서비스가 마음에 들지 않아요.",
    "오류가 있어요.",
    "자주 사용하지 않아요.",
    "기능이 부족해요.",
    "도움이 되지 않아요.",
    "계정을 변경하려고요.",
    "기타",
  ];

  const handleExit = () => {
    console.log("회원탈퇴");
    goToCompleteDeleteAccount();
  };
  return (
    <div className={styles.exitSurveyBox}>
      <h1 className={styles.title}>회원 탈퇴</h1>
      <div className={styles.exitSurveyBox}>
        <h2 className={styles.subTitle}>떠나시는 이유가 있을까요?</h2>
        <div className={styles.line} />
        <div className={styles.exitReasonsBox}>
          {reasons.map((reason, index) => (
            <div key={index} className={styles.reason}>
              <input
                type="radio"
                name="exit-survey"
                id={`exit-survey-${index}`}
              />
              <label htmlFor={`exit-survey-${index}`}>{reason}</label>
            </div>
          ))}
          <textarea
            className={styles.reasonInput}
            placeholder="직접입력(20자이상)"
          />
        </div>
      </div>
      <button className={styles.exitButton} onClick={handleExit}>
        회원탈퇴
      </button>
    </div>
  );
};
export default ExitSurvey;
