import KeywordCountBox from "../../components/RankingSearch/KeywordCountBox";
import StoreInfoCardList from "../../components/RankingSearch/StoreInfoCardList";
import { useParams } from "react-router-dom";
import style from "./RankingSearch.module.scss";
import { useAppNavigate } from "../../utils/navigate/navigate";
import { Tooltip as ReactTooltip } from "react-tooltip";

const RankingSearchResult = () => {
  const { goToRankingSearch } = useAppNavigate();
  const { keyword } = useParams("keyword");
  return (
    <div className={style.rankingSearchResultContainer}>
      <div className={style.rankingSearchResultTopContainer}>
        <button
          className={style.rankingSearchResultBackButton}
          onClick={() => {
            goToRankingSearch();
          }}
        >
          다시 검색하러 가기
        </button>

        <h1 className={style.rankingSearchResultTitle}>
          검색한 키워드 <span> 이태원 맛집{keyword}</span>
        </h1>

        <KeywordCountBox />
      </div>
      <div className={style.storeInfoCardListContainer}>
        <i
          className={style.rankingSearchResultQuestionMark}
          data-tooltip-id="alarmInfo"
        ></i>
        <ReactTooltip
          place="right"
          type="dark"
          effect="solid"
          id="alarmInfo"
          multiline={true}
          className={style.customTooltip}
        >
          <span>
            키워드 순위 알람을 받고 싶다면
            <br />
            업체 등록 후 키워드를 추가해 주세요!
          </span>
        </ReactTooltip>
        <StoreInfoCardList />
        <p className={style.rankingSearchResultDescription}>
          <div className={style.rankingSearchResultDescriptionText}>
            업체명이 없으신가요?
            <br />
            <span className={style.blueText}>
              그렇다면 정확한 URL을 입력해 주세요.
            </span>
          </div>
          <span className={style.rankingSearchResultDescriptionLink}>
            URL 검색 가기<i></i>
          </span>
        </p>
      </div>
    </div>
  );
};

export default RankingSearchResult;
