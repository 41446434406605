import style from "./RankingSearchStyle.module.scss";
import StoreInfoCard from "./StoreInfoCard";
import { useState } from "react";
const StoreInfoCardList = () => {
  // TODO 데이터 받아오기
  // 페이징 처리후 ui 재포딩 조건 추가
  // 더미 데이터 정의
  const [storeData, setStoreData] = useState([
    {
      id: 1,
      image: "https://picsum.photos/200/300",
      name: "가게명1",
      category: "카테고리1",
      visitorReviews: 120,
      blogReviews: 30,
      saves: 50,
      rank: 1,
      pcRankPage: 1,
      pcRank: 10,
      mobileRankPage: 3,
      mobileRank: 2,
      isRegistered: true,
    },
    {
      id: 2,
      image: "https://picsum.photos/200/300",
      name: "가게명2",
      category: "카테고리2",
      visitorReviews: 120,
      blogReviews: 30,
      saves: 50,
      rank: 2,
      pcRankPage: 1,
      pcRank: 20,
      mobileRankPage: 3,
      mobileRank: 2,
      isRegistered: false,
    },
    // 추가 데이터 필요시 여기에 더 추가
  ]);
  return (
    <div className={style.storeInfoCardBox}>
      <span className={style.storeInfoCardListTitle}>실시간 검색 결과</span>

      <div className={style.storeInfoCardList}>
        {storeData.map((store) => (
          <StoreInfoCard key={store.id} {...store} />
        ))}
      </div>
    </div>
  );
};

export default StoreInfoCardList;
