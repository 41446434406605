import style from "./RegisteredPlaceStyle.module.scss";
import PropTypes from "prop-types";
import { useAppNavigate } from "../../utils/navigate/navigate";
import ConfirmDialog from "../Common/ConfirmDialog";
import { useState } from "react";
const RegisterStoreCard = ({
  id,
  image,
  name,
  category,
  visitorReviews,
  blogReviews,
  saves,
  registerDate,
  registeredKeywordRank,
}) => {
  const { goToRegisteredPlaceDetail } = useAppNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 상태를 관리하는 상태 변수와 setter 함수

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleDelete = () => {
    setIsModalOpen(true);
  };

  return (
    <div className={style.registerStoreCard}>
      {image && (
        <img src={image} alt={name} className={style.registerStoreCardImage} />
      )}
      <div
        className={style.registerStoreCardLeftItems}
        onClick={() => {
          goToRegisteredPlaceDetail({ placeId: id });
        }}
      >
        <div className={style.registerStoreCardContentHeader}>
          {name && <p>{name}</p>}
          {category && <p className={style.category}>{category}</p>}
        </div>

        <div className={style.registerStoreCardContentMiddle}>
          <div className={style.registerStoreCardContentMiddleItem}>
            방문자 리뷰
            {visitorReviews && <p>{visitorReviews}</p>}
          </div>
          <div className={style.registerStoreCardContentMiddleItem}>
            블로그 리뷰
            {blogReviews && <p>{blogReviews}</p>}
          </div>
          <div className={style.registerStoreCardContentMiddleItem}>
            저장수
            {saves && <p>{saves}</p>}
          </div>
        </div>

        <div className={style.registerStoreCardContentFooter}>
          {registeredKeywordRank &&
            registeredKeywordRank.map((rank) => (
              <p key={id}>
                {rank.rank}위
                <span className={style.keyword}>{rank.keyword}</span>
              </p>
            ))}
        </div>
      </div>
      <div className={style.registerStoreCardRightItems}>
        <button
          className={style.registerStoreCardButton}
          onClick={handleDelete}
        >
          삭제
        </button>
        <p>등록일 : {registerDate}</p>
      </div>

      {/* 모달 컴포넌트를 조건부 렌더링 */}
      {isModalOpen && (
        <ConfirmDialog
          open={isModalOpen}
          onClose={handleClose}
          onConfirm={() => {
            // 삭제 로직을 여기에 구현
            console.log("삭제 처리");
            handleClose();
          }}
          title={
            <span>
              선택한 플레이스 내역이 영구 삭제됩니다.
              <br />
              정말 삭제하시겠습니까?
            </span>
          }
          cancelText="취소"
          confirmText="삭제"
        ></ConfirmDialog>
      )}
    </div>
  );
};

RegisterStoreCard.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  category: PropTypes.string,
  visitorReviews: PropTypes.number,
  blogReviews: PropTypes.number,
  saves: PropTypes.number,
  registeredKeywordRank: PropTypes.array,
};

export default RegisterStoreCard;
