const URL_PATH = {
  home: "/",
  login: "/login",
  emailLogin: "/login/emailLogin",
  signup: "/signup",
  signupComplete: "/signup/complete",
  findEmailPassword: "/findEmailPassword/:isEmail",
  findEmailComplete: "/findEmailPassword/findEmailComplete", // 추가된 경로
  findPasswordComplete: "/findEmailPassword/findPasswordComplete", // 추가된 경로
  rankingSearch: "/rankingSearch",
  rankingSearchResult: "/rankingSearch/result/:placeKeyword/:placeNameOrUrl",
  registeredPlace: "/registeredPlace",
  registeredPlaceDetail: "/registeredPlace/:placeId",
  membership: "/mypage/membership",
  myMembership: "/mypage/myMembership",
  modifyMemberInfo: "/mypage/modifyMemberInfo",
  completeDeleteAccount: "/mypage/completeDeleteAccount",
  exitSurvey: "/mypage/exitSurvey",
  firstLoginBenefits: "/login/firstLoginBenefits",
};

export default URL_PATH;
