//이미지
import loginStyle from "./Login.module.scss";
import AdditionalLinks from "../../components/Login/AdditionalLinks";
import LoginForm from "../../components/Login/LoginForm";
import SimpleLoginButtons from "../../components/Login/SimpleLoginButtons";

import URL_PATH from "../../utils/constants/urlPath";
function EmailLogin() {
  const handleLogin = (email, password) => {
    console.log("로그인 시도:", email, password);
    // 로그인 로직
  };

  return (
    <div className={loginStyle.loginContainer}>
      <div className={loginStyle.loginText}>
        지금 로그인하고 다양한 <br /> 기능을 바로 경험해보세요!
      </div>
      <div
        className={`${loginStyle.loginCommonContainer} ${loginStyle.simpleLoginContainer}`}
      >
        <LoginForm />
        <AdditionalLinks />
        <SimpleLoginButtons />
      </div>
    </div>
  );
}

export default EmailLogin;
