import style from "./DetailRegisteredPlaceStyle.module.scss";
import { useState } from "react";
import { map } from "lodash";

const KeywordManager = ({
  onRegisterKeyword,
  rankingTableData,
  onRemoveKeyword,
}) => {
  const [keyword, setKeyword] = useState("");

  const handleRegisterClick = () => {
    if (keyword.trim() === "") {
      alert("키워드를 입력하세요");
      return;
    }
    onRegisterKeyword(keyword); // 상위 컴포넌트 메서드 호출
    setKeyword(""); // 입력 필드 초기화
  };

  return (
    <div className={style.keywordManagerBox}>
      <div className={style.keywordManagerTitle}>
        키워드 등록
        <br />
        <span className={style.keywordManagerSubTitle}>
          <span className={style.keywordManagerSubTitleBold}>
            지역 + 카테고리
          </span>
          로 검색 시 정확한 키워드를 검색할 수 있어요!
        </span>
      </div>

      <div className={style.keywordInputBox}>
        <input
          className={style.keywordInput}
          type="text"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          placeholder="지역+카테고리로 키워드를 검색해 보세요.(ex. 이태원 이자카야)"
        />
        <button onClick={handleRegisterClick} className={style.registerButton}>
          등록
        </button>
      </div>

      <div className={style.keywordListBox}>
        {map(rankingTableData, (data) => (
          <div key={data.id} className={style.keywordItem}>
            {data.keyword}
            <button
              onClick={() => onRemoveKeyword(data.id)}
              className={style.removeButton}
            ></button>
          </div>
        ))}
      </div>

      <span className={style.announcementContent}>
        * 한번 삭제된 키워드 로그는 복구가 불가능하니, 삭제 전에 신중히 확인해
        주시기 바랍니다.
        <br />* 하루에 2번 업데이트되므로 최종 변경되는 순위로 로그값이
        보여집니다.
      </span>
    </div>
  );
};

export default KeywordManager;
