import { useState, useEffect } from "react";
import MembershipStyle from "./AccountStyle.module.scss";
import Input from "./Input";
import { VALIDATIONS } from "../../../utils/constants/validations";
import ConfirmDialog from "../../Common/ConfirmDialog";
import { useAppNavigate } from "../../../utils/navigate/navigate";

const ChangeUserInfoForm = () => {
  const { goToExitSurvey } = useAppNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 상태를 관리하는 상태 변수와 setter 함수
  const [isVisibleChangePhoneBox, setIsVisibleChangePhoneBox] = useState(false);

  const [isVisibleAuthNumberBox, setIsVisibleAuthNumberBox] = useState(false);

  // TODO :  유저 정보 받아오기 (이메일,휴대폰 번호, 이름)
  const [userInfo, setUserInfo] = useState({
    email: "amplify000@naver.com",
    phone: "010-0000-0000",
    name: "홍길동 ",
    changePhoneNumber: "",
    authNumber: "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
    changePhoneNumber: false,
    authNumber: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prev) => ({ ...prev, [name]: value }));
    // 여기에 유효성 검사 로직 추가
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const resetForm = () => {
    setUserInfo({
      changePhoneNumber: "",
      authNumber: "",
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  const handleDelete = () => {
    console.log("delete");
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleConfirm = () => {
    console.log("confirm");
    //TODO: 회원탈퇴 백엔드에 요청
    setIsModalOpen(false);
    goToExitSurvey();
  };

  const showChangePhoneNumberBox = (e) => {
    e.preventDefault();
    setIsVisibleChangePhoneBox(true);
  };

  const handleSendAuthNumber = (e) => {
    e.preventDefault();
    const form = e.target.form; // form 요소에 접근
    if (form.checkValidity()) {
      // form 내의 모든 입력값이 유효한지 검사
      setIsVisibleAuthNumberBox(true);
      console.log("send auth number");
    } else {
      form.reportValidity(); // 유효하지 않은 입력에 대해 사용자에게 알림
    }
  };

  const handleCheckAuthNumber = (e) => {
    e.preventDefault();
    console.log("check auth number");
  };
  return (
    <div className={MembershipStyle.changeUserInfoForm}>
      <form
        className={MembershipStyle.changeUserInfoFormBox}
        onSubmit={handleSubmit}
      >
        <Input
          label={VALIDATIONS.email.label}
          type={VALIDATIONS.email.type}
          name="email"
          value={userInfo.email}
          disabled={true}
        />
        <div className={MembershipStyle.phoneNumberBox}>
          <Input
            label={VALIDATIONS.phoneNumber.label}
            type={VALIDATIONS.phoneNumber.type}
            name="phoneNumber"
            value={userInfo.phone}
            onChange={handleInputChange}
            disabled={true}
          />

          <button
            className={MembershipStyle.changePhoneNumberButton}
            onClick={showChangePhoneNumberBox}
          >
            변경
          </button>
        </div>

        {isVisibleChangePhoneBox ? (
          <form className={MembershipStyle.phoneNumberBox}>
            <Input
              label={VALIDATIONS.changePhoneNumber.label}
              type={VALIDATIONS.changePhoneNumber.type}
              placeholder={VALIDATIONS.changePhoneNumber.placeholder}
              name="changePhoneNumber"
              value={userInfo.changePhoneNumber}
              onChange={handleInputChange}
              pattern={VALIDATIONS.changePhoneNumber.pattern}
              required={true}
            />
            <button
              className={MembershipStyle.sendAuthNumberButton}
              onClick={handleSendAuthNumber}
            >
              인증번호 발송
            </button>
          </form>
        ) : null}

        {isVisibleAuthNumberBox ? (
          <form className={MembershipStyle.phoneNumberBox}>
            <Input
              label="휴대폰 인증번호"
              name="authNumber"
              type="text"
              placeholder="인증번호를 입력해 주세요."
              value={userInfo.authNumber}
              onChange={handleInputChange}
              required={true}
            />

            <button
              className={MembershipStyle.checkAuthNumberButton}
              onClick={handleCheckAuthNumber}
              disabled={!userInfo.authNumber}
            >
              인증번호 확인
            </button>
          </form>
        ) : null}

        <Input
          label={VALIDATIONS.name.label}
          type={VALIDATIONS.name.type}
          name="name"
          value={userInfo.name}
          disabled={true}
        />
        <Input
          label="현재 비밀번호"
          type={VALIDATIONS.password.type}
          name="password"
          value={userInfo.password}
          onChange={handleInputChange}
          placeholder="현재 비밀번호"
          pattern={VALIDATIONS.password.pattern}
          required={true}
          errorMessage="입력하신 비밀번호가 일치하지 않습니다."
          isValid={!errors.password}
          maxLength={VALIDATIONS.password.maxLength}
        />

        <Input
          label="현재 비밀번호"
          type={VALIDATIONS.password.type}
          name="newPassword"
          value={userInfo.newPassword}
          onChange={handleInputChange}
          placeholder="새 비밀번호 (영문,소문자와 숫자포함 8~12자)"
          pattern={VALIDATIONS.password.pattern}
          required={true}
          isValid={!errors.newPassword}
          maxLength={VALIDATIONS.password.maxLength}
        />

        <Input
          label="새 비밀번호 확인"
          type={VALIDATIONS.password.type}
          name="confirmPassword"
          value={userInfo.confirmPassword}
          onChange={handleInputChange}
          placeholder="새 비밀번호 확인"
          pattern={VALIDATIONS.password.pattern}
          required={true}
          isValid={!errors.confirmPassword}
          maxLength={VALIDATIONS.password.maxLength}
        />
        <div className={MembershipStyle.buttonBox}>
          <button type="submit">저장</button>
          <button type="reset" onClick={resetForm}>
            취소
          </button>
        </div>
      </form>

      <button className={MembershipStyle.deleteAccount} onClick={handleDelete}>
        회원탈퇴
      </button>

      <ConfirmDialog
        open={isModalOpen}
        onClose={handleClose}
        onConfirm={handleConfirm}
        title={
          <>
            탈퇴하시면 모든 데이터가 사라집니다. <br /> 정말로 탈퇴하시겠어요?
          </>
        }
        cancelText="취소"
        confirmText="탈퇴"
      />
    </div>
  );
};

export default ChangeUserInfoForm;
