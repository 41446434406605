import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./Home.module.scss";
import "./Home.module.scss";
import FirstSlideItem from "../../components/Home/FirstSlideItem";
import SecondSlideItem from "../../components/Home/SecondSlideItem";
import ThirdSlideItem from "../../components/Home/ThirdSlideItem";
import FourthSlideItem from "../../components/Home/FourthSlideItem";
import FifthSlideItem from "../Login/Login";
import leftArrowIcon from "../../assets/icons/left_arrow_icon.svg";
import rightArrowIcon from "../../assets/icons/right_arrow_icon.svg";
import setBodyColor from "../../utils/css/customCss";
import { useEffect, useState, useRef } from "react";

export default function Home() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef();

  const goToSlide = (index) => {
    sliderRef.current.slickGoTo(index);
  };

  const goToPrevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const goToNextSlide = () => {
    sliderRef.current.slickNext();
  };

  useEffect(() => {
    if (currentSlide === 0) {
      setBodyColor("#EEF5FF"); // 홈 화면의 배경색 변경
    } else if (currentSlide === 1 || currentSlide === 2) {
      setBodyColor("linear-gradient(180deg, #fcfdff 24%, #f5f5f5 98%)"); // 다른 페이지로 이동할 때 원래 색상으로 복원
    } else {
      setBodyColor("#fcfdff"); // 다른 페이지로 이동할 때 원래 색상으로 복원
    }
    return () => {
      setBodyColor("#fcfdff"); // 다른 페이지로 이동할 때 원래 색상으로 복원
    };
  }, [currentSlide]);

  var settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 2000,
    centerMode: true,
    centerPadding: "0px", // 중앙 모드에서 양 옆의 슬라이드 보이지 않게 설정
    adaptiveHeight: true,
    arrows: false,

    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
      document.querySelectorAll(".slick-slide").forEach((slide) => {
        slide.style.visibility = "hidden";
      });

      document.querySelector(
        `.slick-slide[data-index="${newIndex}"]`
      ).style.visibility = "visible";
    },
    appendDots: (dots) => (
      <div
        style={{
          bottom: "70px",
          //  건너뛰기 버튼 클릭 영역을 가리지 않게 하기 위해  width  값 조절
          // width 값 조절하면서 가운데 정렬도 재조정
          left: "50%",
          width: "fit-content",
          transform: "translate(-50%, -50%)",
        }}
      >
        <ul style={{ margin: "0px" }}>
          {dots.map((dot, index) => (
            <li
              key={index}
              style={{
                marginRight: "11px",
                width: "fit-content",
                height: "fit-content",
              }}
            >
              {dot}
            </li>
          ))}
        </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={
          i === currentSlide
            ? {
              backgroundColor: "#9E9E9E",
              width: "18px",
              height: "18px",
              color: "#9E9E9E",
              border: "1px solid ",
              borderRadius: "50%",
            }
            : {
              backgroundColor: "#E6E6E6",
              width: "18px",
              height: "18px",
              color: "#E6E6E6",
              border: "1px solid ",
              borderRadius: "50%",
            }
        }
      ></div>
    ),
  };
  return (
    <div className={styles.sliderContainer}>
      <button className={styles.prevArrow} onClick={goToPrevSlide}></button>
      <Slider ref={sliderRef} {...settings}>
        <div className={styles.slideItem}>
          <FirstSlideItem />
          <button className={styles.skipButton} onClick={() => goToSlide(4)}>
            건너뛰기
          </button>
        </div>
        <div className={styles.secondSlideItem}>
          <SecondSlideItem />
          <button className={styles.skipButton} onClick={() => goToSlide(4)}>
            건너뛰기
          </button>
        </div>
        <div className={styles.slideItem}>
          <ThirdSlideItem />
          <button className={styles.skipButton} onClick={() => goToSlide(4)}>
            건너뛰기
          </button>
        </div>
        <div className={styles.fourthSlideItem}>
          <FourthSlideItem />
          <button className={styles.skipButton} onClick={() => goToSlide(4)}>
            건너뛰기
          </button>
        </div>
        <div className={styles.slideItem}>
          <FifthSlideItem isHome={true} />
        </div>
      </Slider>
      <button className={styles.nextArrow} onClick={goToNextSlide}></button>
    </div>
  );
}
