import findEmailPasswordStyles from "./FindEmailPassword.module.scss"; // 스타일 시트 임포트
import GuideTextContainer from "../../components/FindEmailPassword/GuideTextContainer";
import FindEmailCompleteButtons from "../../components/FindEmailPassword/FindEmailCompleteButtons";
import SwitchButtons from "../../components/FindEmailPassword/SwitchButtons";

const FindEmailComplete = () => {
  const email = "test@test.com";

  return (
    <div className={findEmailPasswordStyles.findEmailPasswordContainer}>
      <GuideTextContainer
        title="이메일 / 비밀번호 찾기"
        subtitle="휴대전화 번호 정보와 일치하는 이메일입니다."
      />
      <div className={findEmailPasswordStyles.findEmailCompleteBox}>
        <SwitchButtons
          activeButtonName="email"
          onTabChange={() => {}}
          isFindEmailComplete={true}
        />

        <h2 className={findEmailPasswordStyles.emailText}>
          이메일 : {email} 입니다.
        </h2>
        <FindEmailCompleteButtons />
      </div>
    </div>
  );
};

export default FindEmailComplete;
