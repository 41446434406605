import style from "./CommonStyle.module.scss";
const FloatingButtons = () => {
  return (
    <div className={style.floatingButtonsContainer}>
      <button className={style.askButton}></button>
      <button className={style.topButton}>
        <span>TOP</span>
      </button>
    </div>
  );
};

export default FloatingButtons;
