export const MEMBERSHIP_DATA = {
  basic: {
    membershipName: "Basic",
    membershipCost: "13,900",
    placeCount: 2,
    keywordCount: 5,
    alarmCount: 5,
    isSubscribed: true,
  },
  standard: {
    membershipName: "Standard",
    membershipCost: "25,900",
    placeCount: 5,
    keywordCount: 6,
    alarmCount: 10,
    isSubscribed: false,
  },
  premium: {
    membershipName: "Premium",
    membershipCost: "38,900",
    placeCount: 10,
    keywordCount: 6,
    alarmCount: 10,
    isSubscribed: false,
  },
};
