import loginStyle from "./LoginStyle.module.scss";
import { Link } from "react-router-dom";
import URL_PATH from "../../utils/constants/urlPath";
import { useAppNavigate } from "../../utils/navigate/navigate";

function LoginButtons() {
  const { goToEmailLogin } = useAppNavigate();

  return (
    <div className={loginStyle.loginCommonContainer}>
      <button
        className={`${loginStyle.commonLoginButtons} ${loginStyle.kakaoLoginButton}`}
      >
        Kakao 로그인 하기
      </button>
      <button
        className={`${loginStyle.commonLoginButtons} ${loginStyle.naverLoginButton}`}
      >
        Naver 로그인 하기
      </button>
      <button
        className={`${loginStyle.commonLoginButtons} ${loginStyle.emailLoginButton}`}
        onClick={() => goToEmailLogin(true)}
      >
        이메일 로그인 하기
      </button>
    </div>
  );
}

export default LoginButtons;
