import FooterStyle from "./FooterStyle.module.scss";
import { ReactComponent as Logo } from "../../assets/logo/text.svg";

function Footer() {
  return (
    <div className={FooterStyle.footerBox}>
      <div className={FooterStyle.top}>
        <Logo className={FooterStyle.logo} />
        <span className={FooterStyle.text}>@amplify AII Right Reserved</span>
      </div>
      <div className={FooterStyle.middle}>
        <span className={FooterStyle.address}>
          대표 : 전유상 | 주소 : 서울시 강남구 강남대로 122길 61-4, 2층
        </span>
        <span className={FooterStyle.businessNumber}>
          <br />
          사업자등록번호 : 807-24-01629
        </span>
      </div>

      <div className={FooterStyle.footerBottom}>
        <ul className={FooterStyle.footerBottomList}>
          <li>이용약관</li>
          <li>개인정보처리방침</li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
