import styles from "./HomeStyle.module.scss";
import secondSlideImage from "../../assets/images/phone_mock_up.png";
const SecondSlideItem = () => {
  return (
    <div className={styles.secondSlideBox}>
      <section className={styles.secondSlideTextarea}>
        <span className={styles.secondSlideTitle}>
          노출순위가 얼마나 <br />
          중요한지 잘 아시나요?
        </span>
        <span className={styles.secondSlideDescription}>
          상위에 있을수록 매출이 확 달라집니다.
        </span>
      </section>

      <section className={styles.secondSlideImage}>
        <img
          className={styles.secondSlideImage}
          src={secondSlideImage}
          alt="secondSlideImage"
        />
      </section>
    </div>
  );
};

export default SecondSlideItem;
