import React, { useState } from "react";
import loginStyle from "./LoginStyle.module.scss";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // const handleLogin = async () => {
  //   try {
  //     const userData = await loginUser({ email, password });
  //     console.log("Login successful", userData);
  //     // 로그인 성공 후 처리 로직
  //   } catch (error) {
  //     console.error("Login failed", error);
  //   }
  // };

  return (
    <div className={loginStyle.loginCommonContainer}>
      <input
        type="email"
        value={email}
        className={loginStyle.loginFormInput}
        placeholder="이메일"
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        value={password}
        className={loginStyle.loginFormInput}
        placeholder="비밀번호"
        onChange={(e) => setPassword(e.target.value)}
      />
      <button
        className={`${loginStyle.emailLoginButton} ${loginStyle.commonLoginButtons}`}
      >
        로그인
      </button>
    </div>
  );
};

export default LoginForm;
