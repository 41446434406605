import React, { useState } from "react";
import AuthContext from "../contexts/AuthContext";

const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(true); // 로그인 상태 관리

  const login = () => setIsLoggedIn(true); // 로그인 처리 함수
  const logout = () => setIsLoggedIn(false); // 로그아웃 처리 함수

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
