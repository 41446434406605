import MembershipInfoStyle from "./Account.module.scss";
import ChangeUserInfoForm from "../../../components/Mypage/AccountSettings/ChangeUserInfoForm";
import MembershipCancelBox from "../../../components/Mypage/AccountSettings/MembershipCancelBox";
const MembershipInfo = () => {
  return (
    <div className={MembershipInfoStyle.membershipInfoBox}>
      <div className={MembershipInfoStyle.modifyInfoBox}>
        <h1 className={MembershipInfoStyle.title}>회원정보</h1>
        <ChangeUserInfoForm />
      </div>

      <div className={MembershipInfoStyle.membershipCancelBox}>
        <MembershipCancelBox />
      </div>
    </div>
  );
};

export default MembershipInfo;
