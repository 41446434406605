import MypageStyle from "./MymbershipStyle.module.scss";
import PropTypes from "prop-types";
import { MEMBERSHIP_DATA } from "../../../utils/constants/membership";

const UsingMembershipCard = ({
  isSubscribe,
  isFreeTrial,
  subscriptionType,
  startDate,
  endDate,
}) => {
  if (!isSubscribe)
    return (
      <div className={MypageStyle.usingMembershipCard}>
        <span className={MypageStyle.noMembershipText}>
          현재 이용중인 멤버십이 없습니다.
        </span>
      </div>
    );

  return (
    <div className={MypageStyle.usingMembershipCard}>
      {!isFreeTrial ? (
        <div className={MypageStyle.monthlyTag}>monthly</div>
      ) : null}
      <div className={MypageStyle.membershipInfoBox}>
        <div className={MypageStyle.leftContent}>
          <span
            className={`${MypageStyle.membershipType} ${
              isFreeTrial ? MypageStyle.freeTrial : ""
            }`}
          >
            {subscriptionType}
          </span>
          <span className={MypageStyle.useDate}>
            이용기간 : {startDate}~ {endDate}
            <br />
            {isFreeTrial ? "(2주간)" : null}
          </span>

          <span className={MypageStyle.endDate}>
            {isFreeTrial ? "이용 만료일 : " : "다음 결제일 : "}
            <span className={MypageStyle.redText}>{endDate}</span>
          </span>

          {isFreeTrial ? (
            <span className={MypageStyle.freeTrial}>
              {"이용 금액 : "}
              <span className={MypageStyle.useCost}>
                {MEMBERSHIP_DATA[subscriptionType].membershipCost + "원"}
              </span>
              <span className={MypageStyle.redText}> 0원</span>
            </span>
          ) : null}
        </div>

        <div className={MypageStyle.line}></div>

        <div className={MypageStyle.rightContent}>
          <span>혜택</span>

          <span>실시간 순위분석</span>
          <span>
            등록가능한 플레이스 {MEMBERSHIP_DATA[subscriptionType].placeCount}건
          </span>

          {subscriptionType !== "premium" ? (
            <span>
              플레이스 별 등록가능한 키워드 <br />
              {MEMBERSHIP_DATA[subscriptionType].keywordCount}건(총{" "}
              {MEMBERSHIP_DATA[subscriptionType].keywordCount *
                MEMBERSHIP_DATA[subscriptionType].placeCount}
              개)
            </span>
          ) : (
            <span>
              키워드{" "}
              {MEMBERSHIP_DATA[subscriptionType].keywordCount *
                MEMBERSHIP_DATA[subscriptionType].placeCount}
              개를 원하는
              <br />
              플레이스에 자유롭게 등록
            </span>
          )}

          {subscriptionType !== "basic" ? (
            <span>
              순위로그+세부로그 분석 <br />
              (저장,리뷰,블로그 수)
            </span>
          ) : null}

          <span>
            순위 알림 수 {MEMBERSHIP_DATA[subscriptionType].alarmCount}건
          </span>
        </div>
      </div>
    </div>
  );
};

UsingMembershipCard.propTypes = {
  isSubscribe: PropTypes.bool.isRequired,
  isFreeTrial: PropTypes.bool.isRequired,
  subscriptionType: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

export default UsingMembershipCard;
