import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import EmailLogin from "./pages/Login/EmailLogin";
import SignUp from "./pages/SignUp/SignUp";
import SignUpComplete from "./pages/SignUp/SignUpComplete";
import FindEmailPassword from "./pages/FindEmailPassword/FindEmailPassword";
import FindEmailComplete from "./pages/FindEmailPassword/FindEmailComplete";
import FindPasswordComplete from "./pages/FindEmailPassword/FindPasswordComplete";
import RankingSearch from "./pages/RankingSearch/RankingSearch";
import AuthHeader from "./components/Header/AuthHeader";
import RankingSearchResult from "./pages/RankingSearch/RankingSearchResult";
import RegisteredPlace from "./pages/RegisteredPlace/PlaceSearchListView";
import DetailView from "./pages/DetailRegisteredPlace/DetailView";
import AuthContext from "./utils/contexts/AuthContext";
import Membership from "./pages/Membership/Membership";
import FloatingButtons from "./components/Common/FloatingButtons";
import FooterLayout from "./layouts/Footer";
import MyMebership from "./pages/Mypage/MyMembership/MyMembership";
import MembershipInfo from "./pages/Mypage/AccountSettings/MembershipInfo";
import { useDispatch } from "react-redux";
import { getMembershipData } from "./utils/api/membership";
import { fetchMembershipInitial } from "./features/membership/membershipSlice";
import CompleteDeleteAccount from "./pages/Mypage/MyMembership/CompleteDeleteAccount";
import ExitSurvey from "./pages/Mypage/MyMembership/ExitSurvey";
import FirstLoginBenefits from "./pages/Login/FirstLoginBenefits";
import NotFound from "./pages/PageError/NotFound";
import { Outlet } from "react-router-dom";

function App() {
  console.log("🚀 ~ App ~ App:");
  const { isLoggedIn, login, logout } = useContext(AuthContext);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("🚀 ~ App ~ useEffect ");

    if (isLoggedIn) {
      getMembershipData(dispatch);
    } else {
      dispatch(fetchMembershipInitial());
    }
  }, [isLoggedIn]);

  return (
    console.log("🚀 ~ App ~ App return:"),
    (
      // url 주소를 바라보고 있음 변경 되면 어떤 것을 보여줄지 결정해줌

      <Router>
        {isLoggedIn ? <AuthHeader /> : null}
        {isLoggedIn ? <FloatingButtons /> : null}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/" element={<FooterLayout />}>
            <Route path="/login">
              <Route index element={<Login />} />
              <Route path="emailLogin" element={<EmailLogin />} />
              <Route path="signup" element={<SignUp />} />
              <Route path="firstLoginBenefits" element={<FirstLoginBenefits />} />
            </Route>
            <Route path="/signup">
              <Route index element={<SignUp />} />
              <Route path="complete" element={<SignUpComplete />} />
            </Route>
            <Route path="/findEmailPassword">
              <Route path=":isEmail" element={<FindEmailPassword />} />
              <Route path="findEmailComplete" element={<FindEmailComplete />} />
              <Route
                path="findPasswordComplete"
                element={<FindPasswordComplete />}
              />
            </Route>
            <Route path="/rankingSearch">
              <Route index element={<RankingSearch />} />
              <Route
                path="result/:placeKeyword/:placeNameOrUrl"
                element={<RankingSearchResult />}
              />
            </Route>
            <Route path="/registeredPlace">
              <Route index element={<RegisteredPlace />} />
              <Route path=":placeId" element={<DetailView />} />
            </Route>


            <Route path="/mypage">
              <Route path="membership" element={<Membership />} />
              <Route path="myMembership" element={<MyMebership />} />
              <Route path="modifyMemberInfo" element={<MembershipInfo />} />
              <Route path="exitSurvey" element={<ExitSurvey />} />
            </Route>
          </Route>

          <Route path="/mypage">
            <Route
              path="completeDeleteAccount"
              element={<CompleteDeleteAccount />}
            />
          </Route>

          <Route path="*" element={<NotFound />} /> // 마지막에 추가


        </Routes>
      </Router >
    )
  );
}

export default App;
