import loginStyle from "./LoginStyle.module.scss";

function SimpleLoginButtons() {
  return (
    <div className={loginStyle.simpleLoginContainer}>
      <div className={loginStyle.simpleLoginButtonsContainer}>
        <div className={loginStyle.divisionLine}></div>
        <div className={loginStyle.simpleLoginText}>SNS 간편 로그인</div>
        <div className={loginStyle.divisionLine}></div>
      </div>
      <div className={loginStyle.circleIconContainer}>
        <button className={loginStyle.naverLoginCircleButton}></button>
        <button className={loginStyle.kakaoLoginCircleButton}></button>
      </div>
    </div>
  );
}

export default SimpleLoginButtons;
