import style from "./RankingSearchStyle.module.scss";

const StoreInfoCard = ({
  image,
  name,
  category,
  visitorReviews,
  blogReviews,
  saves,
  rank,
  pcRankPage,
  mobileRankPage,
  pcRank,
  mobileRank,
  isRegistered,
}) => {
  return (
    <div className={style.storeInfoCard}>
      {image && (
        <img src={image} alt={name} className={style.storeInfoCardImage} />
      )}
      <div className={style.storeInfoCardContent}>
        <div className={style.storeInfoCardContentHeader}>
          {name && <p>{name}</p>}
          {category && <p className={style.category}>{category}</p>}
        </div>

        <div className={style.storeInfoCardContentMiddle}>
          <div className={style.storeInfoCardContentMiddleItem}>
            방문자 리뷰
            {visitorReviews && <p>{visitorReviews}</p>}
          </div>
          <div className={style.storeInfoCardContentMiddleItem}>
            블로그 리뷰
            {blogReviews && <p>{blogReviews}</p>}
          </div>
          <div className={style.storeInfoCardContentMiddleItem}>
            저장수
            {saves && <p>{saves}</p>}
          </div>
        </div>

        <div className={style.storeInfoCardContentFooter}>
          {rank && <p className={style.rank}>{rank}위</p>}
          {pcRankPage && (
            <p>
              PC : {pcRankPage}페이지 / {pcRank}위
            </p>
          )}
          {mobileRankPage && (
            <p>
              Mobile : {mobileRankPage}페이지 / {mobileRank}위
            </p>
          )}
        </div>
      </div>
      <button className={style.storeInfoCardButton} disabled={isRegistered}>
        {isRegistered ? "등록완료" : "등록"}
      </button>
    </div>
  );
};

export default StoreInfoCard;
