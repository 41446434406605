import { useState } from "react";

import findEmailPasswordStyles from "./FindEmailPasswordStyle.module.scss";
import { useAppNavigate } from "../../utils/navigate/navigate";
import InputField from "../SignUp/InputField";
const FindEmailForm = () => {
  const { goToFindEmailComplete } = useAppNavigate();

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    goToFindEmailComplete();
  };

  return (
    <div className={findEmailPasswordStyles.findEmailFormContainer}>
      <InputField
        name="name"
        value={formData.name}
        onChange={handleChange}
        placeholder="이름을 입력해 주세요."
        pattern="^[가-힣]{2,5}$"
      />
      <div className={findEmailPasswordStyles.authContainer}>
        <InputField
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          placeholder="숫자만 입력해 주세요."
          pattern="^010\d{8}$"
        />

        <button className={findEmailPasswordStyles.authButton}>
          인증번호 전송
        </button>
      </div>

      <div className={findEmailPasswordStyles.authContainer}>
        <InputField
          name="code"
          value={formData.code}
          onChange={handleChange}
          placeholder="인증번호를 입력해 주세요."
          pattern="^[0-9]{6}$"
        />

        <button
          className={findEmailPasswordStyles.authButton}
          onClick={handleSubmit}
        >
          확인
        </button>
      </div>
    </div>
  );
};

export default FindEmailForm;
