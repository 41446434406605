import React, { useState, useContext } from "react";
import styles from "./HeaderStyle.module.scss"; // SCSS 모듈 임포트

import { ReactComponent as PlogLogo } from "../../assets/logo/text.svg";
import AuthContext from "../../utils/contexts/AuthContext";
import { useAppNavigate } from "../../utils/navigate/navigate";

function AuthHeader() {
  // 탭 정보 배열
  const tabs = [
    { name: "실시간 순위분석", route: "/rankingSearch" },
    { name: "등록한 플레이스", route: "/registeredPlace" },
    { name: "멤버십 안내", route: "/membership" },
    { name: "마이페이지", route: "/mypage" },
  ];

  const boxItems = [
    { name: "나의 멤버십", route: "/myMembership" },
    { name: "회원정보 수정", route: "/modifyMemberInfo" },
  ];
  const [activeTab, setActiveTab] = useState(tabs[0].route); // 초기 활성 탭 설정

  const { isLoggedIn, login, logout } = useContext(AuthContext); // Context에서 값 추출
  const [showMypageBox, setShowMypageBox] = useState(false); // 마이페이지 박스 표시 여부
  const [activeMypageItem, setactiveMypageItem] = useState(boxItems[0].route); // 마이페이지 박스 표시 여부
  const {
    goToRankingSearch,
    goToRegisteredPlace,
    goToMembership,
    goToMyMembership,
    goToModifyMemberInfo,
  } = useAppNavigate();

  // 탭 클릭 핸들러
  const handleTabClick = (item, isMypage = false) => {
    console.log(item);
    setActiveTab(item.route);
    setShowMypageBox(false); // 마이페이지 박스 표시 여부

    switch (item.route) {
      case "/rankingSearch":
        goToRankingSearch();
        setactiveMypageItem(null);
        break;
      case "/registeredPlace":
        goToRegisteredPlace();
        setactiveMypageItem(null);
        break;
      case "/membership":
        goToMembership();
        setactiveMypageItem(null);
        break;
      case "/mypage":
        setShowMypageBox(!showMypageBox);
        break;
      case "/myMembership":
        setActiveTab(tabs[3].route);
        setactiveMypageItem(item.route);
        goToMyMembership();
        break;
      case "/modifyMemberInfo":
        setActiveTab(tabs[3].route);
        setactiveMypageItem(item.route);
        goToModifyMemberInfo();
        break;

      default:
        console.log("Unknown route:", item.route);
        break;
    }
  };

  // const handleMypageBoxClick = (route) => {
  //   switch (route) {

  //       break;
  //   }
  //   setActiveTab(tabs[3].route); // 활성 탭 업데이트.
  //   setactiveMypageItem(route); // 마이페이지 박스 탭 활성화
  //   setShowMypageBox(false); // 마이페이지 박스 표시 여부
  // };

  return (
    <header className={styles.headerContainer}>
      <div className={styles.headerContentContainer}>
        <div className={styles.headerLeftContainer}>
          <PlogLogo className={styles.headerLogo} />
        </div>
        <div className={styles.headerRightContainer}>
          <div className={styles.headerTabContainer}>
            {tabs.map((tab) => (
              <div>
                <button
                  key={tab.route}
                  onClick={() => handleTabClick(tab, tab.route === "/mypage")}
                  className={`${styles.headerTab} ${
                    activeTab === tab.route ? styles.active : ""
                  } ${tab.route === "/mypage" ? styles.mypageTab : ""}`}
                >
                  {tab.name}
                </button>

                {tab.route === "/mypage" && (
                  <div
                    className={`${styles.mypageBox} ${
                      showMypageBox ? styles.visible : styles.hidden
                    }`}
                  >
                    {boxItems.map((item) => (
                      <a
                        key={item.route}
                        onClick={() => handleTabClick(item, true)}
                        className={`${styles.mypageBoxItem} ${
                          activeMypageItem === item.route ? styles.selected : ""
                        }`}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>

          <button
            className={styles.headerLogoutButton}
            onClick={isLoggedIn ? logout : login}
          >
            {isLoggedIn ? "Logout" : "Login"}
          </button>
        </div>
      </div>
    </header>
  );
}

export default AuthHeader;
