import style from "./DetailRegisteredPlace.module.scss";
import RegisterFormContainer from "../../components/DetailRegisteredPlace/RegisterFormContainer";
import PlaceInfoFetcher from "../../components/DetailRegisteredPlace/PlaceInfoFetcher";
const DetailView = () => {
  return (
    <div className={style.detailView}>
      <h1>상세 플레이스</h1>
      <div className={style.placeInfoFetcher}>
        <PlaceInfoFetcher />
      </div>
      <div className={style.divider}></div>

      <div className={style.registerFormContainer}>
        <RegisterFormContainer />
      </div>
    </div>
  );
};

export default DetailView;
