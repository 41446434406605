import MembershipStyle from "./AccountStyle.module.scss";
import { useSelector } from "react-redux";
import { MEMBERSHIP_DATA } from "../../../utils/constants/membership";
import ConfirmDialog from "../../Common/ConfirmDialog";
import { useState } from "react";

const MembershipCancelBox = () => {
  const membershipData = useSelector((state) => state.membership);
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 상태를 관리하는 상태 변수와 setter 함수
  if (membershipData.subscription_type === null) {
    return <div>loading</div>;
  }

  const handleCancel = () => {
    setIsModalOpen(true);
    console.log("cancel");
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleConfirm = () => {
    console.log("confirm");
    //TODO: 멤버십 해지 백엔드에 요청
    handleClose();
  };

  return (
    <div className={MembershipStyle.membershipCancelBox}>
      <h1 className={MembershipStyle.title}>멤버십 해지</h1>

      <div className={MembershipStyle.cancelInfoBox}>
        <span className={MembershipStyle.subscriptionType}>
          {membershipData.subscription_type}
        </span>

        <span className={MembershipStyle.cancelInfo}>
          <span className={MembershipStyle.redText}>
            이용 금액{" "}
            {MEMBERSHIP_DATA[membershipData.subscription_type].membershipCost}원
          </span>
          ({membershipData.sub_str_date} ~{membershipData.sub_end_date})
        </span>

        <button className={MembershipStyle.cancelButton} onClick={handleCancel}>
          해지
        </button>
      </div>

      <span className={MembershipStyle.warning}>
        *해지 시 구독 만료일까지 서비스를 이용 가능하며 이후 정기결제는
        이루어지지 않습니다.
      </span>

      <ConfirmDialog
        open={isModalOpen}
        onClose={handleClose}
        onConfirm={handleConfirm}
        title={
          <span>
            정말로 멤버십을 해지하시겠어요? <br />
            구독을 해지하시면 모든 로그 기록이 삭제됩니다.
          </span>
        }
        cancelText="취소"
        confirmText="해지"
      ></ConfirmDialog>
    </div>
  );
};

export default MembershipCancelBox;
