export const VALIDATIONS = {
  email: {
    label: "이메일",
    type: "email",
    placeholder: "이메일(아이디)을 입력해 주세요.",
  },
  phoneNumber: {
    label: "휴대폰 번호",
    type: "text",
    placeholder: "휴대폰 번호를 입력해 주세요.",
    pattern: "[0-9]{10,11}",
  },

  changePhoneNumber: {
    label: "휴대폰 번호 변경",
    type: "text",
    placeholder: "변경할 휴대폰 번호를 입력해 주세요.",
    pattern: "[0-9]{10,11}",
  },
  name: {
    label: "이름",
    type: "text",
    maxLength: 30,
    pattern: "[가-힣]{2,5}$",
  },

  password: {
    label: "비밀번호",
    type: "password",
    minLength: 8,
    maxLength: 12,
    pattern: "(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,12}",
  },
};
