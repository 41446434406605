import styles from "./MyMembership.module.scss";
const CompleteDeleteAccount = () => {
  return (
    <div className={styles.completeDeleteAccountBox}>
      <h1 className={styles.title}>회원탈퇴가 완료되었습니다.</h1>
      <button className={styles.button}>홈으로 가기</button>
    </div>
  );
};
export default CompleteDeleteAccount;
