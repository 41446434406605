import PropTypes from "prop-types";
import signUpStyles from "./SignUpStyle.module.scss";

const InputField = ({
  label,
  type = "text",
  name,
  value,
  onChange,
  placeholder,
  pattern,
  required = false,
  disabled = false,
}) => {
  return (
    <div className={signUpStyles.InputFieldContainer}>
      {label && (
        <label className={signUpStyles.InputFieldLabel} htmlFor={name}>
          {label}
          {label && <span className={signUpStyles.requiredStar}>*</span>}
        </label>
      )}
      <input
        className={`${signUpStyles.InputField} ${
          type === "date" ? signUpStyles.dateInput : ""
        }`}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        pattern={pattern}
        required={required}
        disabled={disabled}
        onFocus={type === "date" ? (e) => e.target.showPicker() : null} // 클릭 시 달력 표시
      />
    </div>
  );
};

InputField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  pattern: PropTypes.string,
  disabled: PropTypes.bool,
};

export default InputField;
