import {
  fetchMembershipStart,
  fetchMembershipSuccess,
  fetchMembershipFailure,
} from "../../features/membership/membershipSlice";

export async function getMembershipData(dispatch) {
  console.log("🚀 ~ getMembershipData ~ getMembershipData:");
  try {
    dispatch(fetchMembershipStart());
    //  const response = await axios.get("https://api.example.com/user");
    dispatch(
      fetchMembershipSuccess({
        subscription_type: "standard",
        is_free_trial: false,
        sub_str_date: "2024-01-01",
        sub_end_date: "2024-01-20",
      })
    ); // 로딩 성공 액션 디스패치
  } catch (err) {
    dispatch(fetchMembershipFailure(err)); // 로딩 실패 액션 디스패치
  }
}
