import style from "./DetailRegisteredPlaceStyle.module.scss";

import KeywordManager from "./KeywordManager";
import RankingTable from "./RankingTable";
import { useState } from "react";
import { map } from "lodash";
import Switch from "../Common/Switch";

// TODO: 키워드 등록 로직 추가
// TODO: 키워드 삭제 로직 추가
// TODO: 키워드 등록 후 랭킹 테이블 업데이트 로직 추가
// TODO: 키워드 등록 삭제시 해당 테이블만 UI 업데이트 되도록 처리

const RegisterFormContainer = () => {
  const [isRankingTableVisible, setIsRankingTableVisible] = useState(false);
  const [rankingTableData, setRankingTableData] = useState([
    {
      id: 1,
      keyword: "키워드1",
      storeName: "가게명1",
      headRank: 1,
      headRankDate: "01.01",
      isAlarmActive: true,
      rankItems: [
        {
          date: "01/01",
          keywordRank: 1,
          saves: 100,
          blogReviews: 100,
          visitorReviews: 100,
        },
        {
          date: "01/02",
          keywordRank: 2,
          saves: 200,
          blogReviews: 200,
          visitorReviews: 200,
        },
        {
          date: "01/03",
          keywordRank: 3,
          saves: 300,
          blogReviews: 300,
          visitorReviews: 300,
        },
        {
          date: "01/04",
          keywordRank: 4,
          saves: 400,
          blogReviews: 400,
          visitorReviews: 400,
        },
        {
          date: "01/05",
          keywordRank: 5,
          saves: 500,
          blogReviews: 500,
          visitorReviews: 500,
        },
        {
          date: "01/05",
          keywordRank: 5,
          saves: 500,
          blogReviews: 500,
          visitorReviews: 500,
        },
        {
          date: "01/05",
          keywordRank: 5,
          saves: 500,
          blogReviews: 500,
          visitorReviews: 500,
        },
        {
          date: "01/05",
          keywordRank: 5,
          saves: 500,
          blogReviews: 500,
          visitorReviews: 500,
        },
        {
          date: "01/05",
          keywordRank: 5,
          saves: 500,
          blogReviews: 500,
          visitorReviews: 500,
        },
        {
          date: "01/05",
          keywordRank: 5,
          saves: 500,
          blogReviews: 500,
          visitorReviews: 500,
        },
        {
          date: "01/05",
          keywordRank: 5,
          saves: 500,
          blogReviews: 500,
          visitorReviews: 500,
        },
        {
          date: "01/05",
          keywordRank: 5,
          saves: 500,
          blogReviews: 500,
          visitorReviews: 500,
        },
        {
          date: "01/05",
          keywordRank: 5,
          saves: 500,
          blogReviews: 500,
          visitorReviews: 500,
        },
        {
          date: "01/05",
          keywordRank: 5,
          saves: 500,
          blogReviews: 500,
          visitorReviews: 500,
        },
        {
          date: "01/05",
          keywordRank: 5,
          saves: 500,
          blogReviews: 500,
          visitorReviews: 500,
        },
        {
          date: "01/05",
          keywordRank: 5,
          saves: 500,
          blogReviews: 500,
          visitorReviews: 500,
        },
        {
          date: "01/05",
          keywordRank: 5,
          saves: 500,
          blogReviews: 500,
          visitorReviews: 500,
        },
        {
          date: "01/05",
          keywordRank: 5,
          saves: 500,
          blogReviews: 500,
          visitorReviews: 500,
        },
      ],
    },
    {
      id: 2,
      keyword: "키워드2",
      storeName: "가게명2",
      headRank: 2,
      headRankDate: "2024.01.01",
      isAlarmActive: false,
      rankItems: [
        {
          date: "01/01",
          keywordRank: 1,
          saves: 100,
          blogReviews: 100,
          visitorReviews: 100,
        },
      ],
    },
    {
      id: 3,
      keyword: "키워드3",
      storeName: "가게명3",
      headRank: 3,
      headRankDate: "2024.01.01",
      isAlarmActive: false,
      rankItems: [
        {
          date: "01/01",
          keywordRank: 1,
          saves: 100,
          blogReviews: 100,
          visitorReviews: 100,
        },
        {
          date: "01/02",
          keywordRank: 2,
          saves: 200,
          blogReviews: 200,
          visitorReviews: 200,
        },
      ],
    },
  ]);

  const [showNotification, setShowNotification] = useState(false);

  const toggleAlarm = (id) => {
    const newData = rankingTableData.map((item) => {
      if (item.id === id) {
        return { ...item, isAlarmActive: !item.isAlarmActive };
      }
      return item;
    });
    setRankingTableData(newData);
  };

  const registerKeyword = (keyword) => {
    // 서버에 키워드 등록 요청 로직 (가상)
    // 예: API.registerKeyword(keyword).then(data => updateRankingTable(data));
    console.log(`등록 요청: ${keyword}`);
    // 임시 데이터 추가 로직
    const newData = {
      id: rankingTableData.length + 1,
      keyword: keyword,
      storeName: "가게명1",
      headRank: 1,
      headRankDate: "01.01",
      isAlarmActive: true,
      rankItems: [
        {
          date: "01/01",
          keywordRank: 1,
          saves: 100,
          blogReviews: 100,
          visitorReviews: 100,
        },
      ],
    };
    setRankingTableData([...rankingTableData, newData]);
  };

  const removeKeyword = (id) => {
    // 서버에 키워드 삭제 요청 로직 (가상)
    // 예: API.removeKeyword(id).then(() => {
    setRankingTableData(rankingTableData.filter((item) => item.id !== id));
    // });
  };

  const handleSwitch = () => {
    setIsRankingTableVisible(!isRankingTableVisible);
  };

  return (
    <div className={style.registerFormContainer}>
      <KeywordManager
        onRegisterKeyword={registerKeyword}
        rankingTableData={rankingTableData}
        onRemoveKeyword={removeKeyword}
      />

      <Switch isOn={isRankingTableVisible} setIsOn={handleSwitch} />

      <div className={style.rankingTableContainer}>
        {map(rankingTableData, (data) => (
          <RankingTable
            key={data.id}
            id={data.id}
            keyword={data.keyword}
            storeName={data.storeName}
            headRank={data.headRank}
            headRankDate={data.headRankDate}
            rankItems={data.rankItems}
            isVisibleMore={isRankingTableVisible}
            isAlarmActive={data.isAlarmActive}
            toggleAlarm={() => toggleAlarm(data.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default RegisterFormContainer;
