import findEmailPasswordStyles from "./FindEmailPasswordStyle.module.scss"; // 스타일 시트 임포트

const GuideTextContainer = ({ title, subtitle }) => {
  return (
    <div className={findEmailPasswordStyles.guideTextContainer}>
      <h1 className={findEmailPasswordStyles.title}>{title}</h1>
      {subtitle && (
        <p className={findEmailPasswordStyles.subtitle}>{subtitle}</p>
      )}
    </div>
  );
};

export default GuideTextContainer;
