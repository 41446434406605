import SignupForm from "../../components/SignUp/SignupForm"; // 경로는 실제 프로젝트 구조에 맞게 조정
import signupStyle from "./SignUp.module.scss";
function SignupPage() {
  return (
    <div className={signupStyle.signupContainer}>
      <h1 className={signupStyle.signupTitle}>회원가입</h1>
      <div className={signupStyle.signupForm}>
        <h2 className={signupStyle.signupSubtitle}>
          회원 정보를 입력해주세요.
        </h2>

        <SignupForm />
      </div>
    </div>
  );
}

export default SignupPage;
