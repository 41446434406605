import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subscription_type: null, // 사용자 데이터 초기값
  loading: false, // 로딩 상태 초기값
  error: null, // 에러 상태 초기값
  is_free_trial: false,
  sub_str_date: null,
  sub_end_date: null,
};

const membershipSlice = createSlice({
  name: "membership", // 슬라이스의 이름
  initialState, // 이 슬라이스의 초기 상태
  reducers: {
    fetchMembershipStart: (state) => {
      console.log("fetchMembershipStart");
      state.loading = true;
      state.error = null;
    },
    fetchMembershipSuccess: (state, action) => {
      console.log("fetchMembershipSuccess");
      state.loading = false;
      state.subscription_type = action.payload.subscription_type;
      state.is_free_trial = action.payload.is_free_trial;
      state.sub_str_date = action.payload.sub_str_date;
      state.sub_end_date = action.payload.sub_end_date;
    },
    fetchMembershipFailure: (state, action) => {
      console.log("fetchMembershipFailure");
      state.loading = false;
      state.error = action.payload;
    },
    fetchMembershipInitial: (state) => {
      console.log("fetchMembershipInitial");
      state.subscription_type = null;
      state.loading = false;
      state.error = null;
      state.is_free_trial = false;
      state.sub_str_date = null;
      state.sub_end_date = null;
    },
  },
});

export const {
  fetchMembershipStart,
  fetchMembershipSuccess,
  fetchMembershipFailure,
  fetchMembershipInitial,
} = membershipSlice.actions;

export default membershipSlice.reducer;
