import style from "./RegisteredPlaceStyle.module.scss";
import RegisterStoreCard from "./registerStoreCard";
import { useState } from "react";
// TODO 데이터 받아오기
// 페이징 처리후 ui 재포딩 조건 추가
// 더미 데이터 정의

const SearchableCardList = () => {
  const [storeData, setStoreData] = useState([
    {
      id: 1,
      image: "https://picsum.photos/200/300",
      name: "가게명1",
      category: "카테고리1",
      visitorReviews: 120,
      blogReviews: 30,
      saves: 50,
      registerDate: "2024.01.01",
      registeredKeywordRank: [
        {
          rank: 1,
          keyword: "키워드1",
        },
        {
          rank: 50,
          keyword: "키워드2",
        },
        {
          rank: 100,
          keyword: "키워드3",
        },
        {
          rank: 20,
          keyword: "키워드4",
        },
        {
          rank: 15,
          keyword: "키워드2",
        },
      ],
    },

    {
      id: 2,
      image: "https://picsum.photos/200/300",
      name: "가게명2",
      category: "카테고리2",
      visitorReviews: 120,
      blogReviews: 30,
      saves: 50,
      registerDate: "2024.01.01",
      registeredKeywordRank: [
        {
          rank: 1,
          keyword: "키워드1",
        },
        {
          rank: 50,
          keyword: "키워드2",
        },
      ],
    },

    {
      id: 3,
      image: "https://picsum.photos/200/300",
      name: "가게명2",
      category: "카테고리2",
      visitorReviews: 120,
      blogReviews: 30,
      saves: 50,
      registerDate: "2024.01.01",
    },

    // 추가 데이터 필요시 여기에 더 추가
  ]);

  return (
    <div className={style.searchableCardList}>
      <div className={style.searchInputBox}>
        <input
          type="text"
          placeholder="검색어를 입력해주세요"
          onChange={(e) => {
            console.log(e.target.value);
          }}
        />
        <button className={style.searchButton}></button>
      </div>

      {storeData.map((store) => (
        <RegisterStoreCard key={store.id} {...store} />
      ))}
    </div>
  );
};

export default SearchableCardList;
