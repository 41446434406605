import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./assets/styles/font.scss";
import AuthProvider from "./utils/providers/AuthProvider";
import { Provider } from "react-redux";
import { store } from "./store";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <Provider store={store}>
      <div className="container">
        <App />
      </div>
    </Provider>
  </AuthProvider>
);
