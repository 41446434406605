import signUpStyles from "./SignUpStyle.module.scss"; // 스타일 시트 임포트

const Checkbox = ({ label, isChecked, onChange, isRequired = false }) => {
  // TODO: 필수 텍스트와 label 텍스트 간격 조절하기
  return (
    <label className={signUpStyles.checkboxContainer}>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
        required={isRequired}
      />
      <span className={signUpStyles.checkmark}></span>
      {label && <span className={signUpStyles.labelText}>{label}</span>}
      {isRequired ? (
        <span className={signUpStyles.required}>(필수)</span>
      ) : null}
    </label>
  );
};

export default Checkbox;
